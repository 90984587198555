import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {Parameters} from '../../components/parametreler'
interface GeneralParamsProps {
  title: string
  data: any[]
}
export const GeneralParams = () => {
  const {model} = useParams<any>()
  return (
    <>
      <Parameters title={model} />
    </>
  )
}
