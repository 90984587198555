import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL || 'api'

export const getModel: any = (model: string) => {
  try {
    return axios.get(`${API_URL}/getir/${model}`)
  } catch (error) {
    console.log(error)
  }
}

export const updateModel: any = (params: any, body: any) => {
  try {
    return axios.patch(`${API_URL}/${params.model}/${params.id}`, body)
  } catch (error) {
    console.log(error)
  }
}

export const createModel: any = (params: any, body: any) => {
  try {
    return axios.post(`${API_URL}/${params.model}`, body)
  } catch (error) {
    console.log(error)
  }
}

export const deleteModel: any = (params: any) => {
  try {
    return axios.delete(`${API_URL}/${params.model}/${params.id}`)
  } catch (error) {
    console.log(error)
  }
}

export const updateParams: any = (data: any) => {
  try {
    return axios.patch(`${API_URL}/updateParams`, data)
  } catch (error) {
    console.log(error)
  }
}