import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {deleteModel, getModel, updateParams} from '../CRUD'

export const Genel = () => {
  const {model} = useParams<any>()
  const [data, setData] = React.useState<any>(null)
  const [formData, setFormData] = React.useState<any>({})
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await getModel(model)
        const result = response?.data?.data
        setData(result)

        const params: any = {}
        Object.entries(result.params).forEach(([key, value]) => {
            params[key] = value
          },
        )
        result.elements.forEach((element: any) => {
          params[element.Adi] = element.EkoFiyat
        })
        setFormData(params)
      }
      fetchData()
    } catch (error) {
      setData([])
      console.log(error)
    }
  }, [model])

  const handleSave = async () => {
    try {
      const response = await updateParams(formData)
      if (response.data) {
        alert('Başarılı')
        window.location.reload()
      } else {
        alert('Başarısız')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="table-responsive table-bordered">
      {/* begin::Table */}
      <table className="table align-middle gs-0 gy-5">
        {/* begin::Table head */}
        <thead>
        <tr className={'text-dark fw-bolder'}>
          <th></th>
          <th className="p-0 w-25"></th>
        </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
        {data &&
          Object.entries(data?.params).map(([key, value], index) => (
            <tr key={index} className="text-dark fw-bolder">
              <td>{key}</td>
              <td>
                <input
                  onChange={(e) => setFormData({...formData, [key]: e.target.value})}
                  value={formData[key] || value}
                  type="number"
                  step={0.01}
                  className="form-control"
                  defaultValue={Number(value)}
                />
              </td>
            </tr>
          ))}
        {
          data && data?.elements.map((element: any, index: number) => (
            <tr key={index} className="text-dark fw-bolder">
              <td>{element.Adi}</td>
              <td>
                <input
                  onChange={(e) => setFormData({...formData, [element.Adi]: e.target.value})}
                  value={formData[element.Adi] || element.EkoFiyat}
                  step={0.01}
                  type="number"
                  className="form-control"
                  defaultValue={element.EkoFiyat}
                />
              </td>
            </tr>
          ))
        }
        </tbody>
        {/* end::Table body */}
      </table>
      <div className="d-flex justify-content-end mt-5">
        <button onClick={handleSave} className="btn btn-primary">
          Kaydet
        </button>
      </div>
      {/* end::Table */}
    </div>
  )
}
